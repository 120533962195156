import '../styles/mobile-home.css';
import issue from '../images/winter-2024-cover.webp';
import SwiperMobile from './SwiperMobile';
import { Carousel } from 'react-responsive-carousel';
// import TwitterFeed from '../components/TwitterFeed';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import podcast_logo from '../images/podcast logo.webp';
import ewma1small from '../images/space-slide.webp';
import summit3poster from '../images/skin-substitutes-poster.webp'
import wound_bed_prep_poster from '../images/MasterSeries May 8 V7.webp'
import stoma1 from '../images/stoma 1.webp'
import stoma2 from '../images/stoma 2.webp'
import stoma3 from '../images/stoma 3.webp'

const MobileHome = ({
  setNotOptions,
  mobileLandscape,
  setMobileLandscape,
  auth,
  podcastLogo,
  setPodcastLogo,
}) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);
  useEffect(() => {
    setPodcastLogo(true);
  }, []);

  // const podcastLogoVisibility = podcastLogo
  //   ? 'podcast-logo-mobile-header'
  //   : 'podcast-logo-mobile-header-hidden';

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  if (!auth) {
    return (
      <>
        <section className='mobile-home-container'>
          
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              showArrows={false}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              interval={6000}
              stopOnHover={false}
            >
              <a href='https://woundmasterclass.com/bioactive-matrix' target='_blank'>
              <div>
                <img src={wound_bed_prep_poster} />
              </div>
              </a>
              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma1} />
              </div>
              </a>
              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma2} />
              </div>
              </a>
              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma3} />
              </div>
              </a>
            </Carousel>
          
          <div>
            <Link to='/Podcast'>
              <img
                src={podcast_logo}
                className='podcast-logo-mobile-header'
              ></img>
            </Link>
          </div>
          <div className='mobile-current-issue-container'>
            <h1 className='mobile-content-titles'>
              <span>WINTER 2024 ISSUE</span>
            </h1>
            <div className='mobile-current-issue-box'>
              <img
                src={issue}
                className='mobile-issue-img'
                alt='Wound Masterclass issue'
              ></img>
              <span id='about-issue'>
                <br />
                <p>
                Our Winter 2024 issue will soon be here. <br />
                  <br />
                  Read more about the journal{' '}
                  <Link to='/About'>
                    <span
                      className='email-links'
                      onClick={() => {
                        document.documentElement.scrollTop = 0;
                      }}
                    >
                      here.
                    </span>
                  </Link>
                  <br />
                  <br />
                  See our international{' '}
                  <Link to='/About'>
                    <span
                      className='email-links'
                      onClick={() => {
                        document.documentElement.scrollTop = 0;
                      }}
                    >
                      editorial board.
                    </span>
                  </Link>
                  <br />
                  <br />
                  Please{' '}
                  <Link to='/Register'>
                    <span className='email-links' onClick={() => scrollToTop()}>
                      register{' '}
                    </span>
                  </Link>{' '}
                  with us to get free, full access to the journal.
                  <br />
                  <br />
                </p>
              </span>
            </div>
          </div>
          <div className='mobile-featured-content-container'>
            <h2 className='mobile-content-titles'>
              <span>CONTENT THIS ISSUE</span>
            </h2>
            <div className='mobile-featured-content-box'>
              <SwiperMobile />
            </div>
          </div>
        </section>
        {/* <div className='twitter-feed-container'>
        <div className='twitter-feed-box'>
          <TwitterFeed />
        </div>
      </div> */}

        {/* <div className='offer-mobile-container'>
        <img
          src={offer_mobile}
          className='offer-mobile-home'
          alt='register offer'
        ></img>
      </div> */}
      </>
    );
  } else {
    return (
      <>
        <section className='mobile-home-container'>
          
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              showArrows={false}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              interval={6000}
              stopOnHover={false}
            >
              <a href='https://woundmasterclass.com/bioactive-matrix' target='_blank'>
              <div>
                <img src={wound_bed_prep_poster} />
              </div>
              </a>
              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma1} />
              </div>
              </a>
              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma2} />
              </div>
              </a>
              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma3} />
              </div>
              </a>
              
            </Carousel>
          
          <div>
            <Link to='/Podcast'>
              <img
                src={podcast_logo}
                className='podcast-logo-mobile-header'
              ></img>
            </Link>
          </div>
          <div className='mobile-current-issue-container'>
            <h1 className='mobile-content-titles'>
              <span>SPRING 2024 ISSUE</span>
            </h1>
            <div className='mobile-current-issue-box'>
            <div
  style={{
    position: "relative",
    paddingTop: "max(60%,326px)",
    height: 0,
    width: "100%"
  }}
>
  <iframe
    allow="clipboard-write"
    sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
    allowFullScreen="true"
    style={{
      position: "absolute",
      border: "none",
      width: "100%",
      height: "100%",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }}
    src="https://e.issuu.com/embed.html?d=wound_masterclass_-_march_2024&hideIssuuLogo=true&u=woundmasterclass"
  />
</div>

            </div>
          </div>
          <div className='mobile-featured-content-container'>
            <h2 className='mobile-content-titles'>
              <span>CONTENT THIS ISSUE</span>
            </h2>
            <div className='mobile-featured-content-box'>
              <SwiperMobile />
            </div>
          </div>
        </section>
        {/* <div className='twitter-feed-container'>
            <div className='twitter-feed-box'>
              <TwitterFeed />
            </div>
          </div> */}

        {/* <div className='offer-mobile-container'>
            <img
              src={offer_mobile}
              className='offer-mobile-home'
              alt='register offer'
            ></img>
          </div> */}
      </>
    );
  }
};

export default MobileHome;
